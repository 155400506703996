exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-graduacao-jsx": () => import("./../../../src/pages/graduacao.jsx" /* webpackChunkName: "component---src-pages-graduacao-jsx" */),
  "component---src-pages-internationalstudents-jsx": () => import("./../../../src/pages/internationalstudents.jsx" /* webpackChunkName: "component---src-pages-internationalstudents-jsx" */),
  "component---src-pages-modalidades-jsx": () => import("./../../../src/pages/modalidades.jsx" /* webpackChunkName: "component---src-pages-modalidades-jsx" */),
  "component---src-pages-redirecionamentos-apadrinhe-um-intercambista-jsx": () => import("./../../../src/pages/redirecionamentos/apadrinheUmIntercambista.jsx" /* webpackChunkName: "component---src-pages-redirecionamentos-apadrinhe-um-intercambista-jsx" */),
  "component---src-pages-sobre-jsx": () => import("./../../../src/pages/sobre.jsx" /* webpackChunkName: "component---src-pages-sobre-jsx" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-contato-jsx": () => import("./../../../src/templates/contato.jsx" /* webpackChunkName: "component---src-templates-contato-jsx" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-intercambio-page-jsx": () => import("./../../../src/templates/intercambioPage.jsx" /* webpackChunkName: "component---src-templates-intercambio-page-jsx" */),
  "component---src-templates-intercambios-escolar-js": () => import("./../../../src/templates/intercambiosEscolar.js" /* webpackChunkName: "component---src-templates-intercambios-escolar-js" */),
  "component---src-templates-intercambios-js": () => import("./../../../src/templates/intercambios.js" /* webpackChunkName: "component---src-templates-intercambios-js" */)
}

